<template>
  <label class="custom-switch">
    <div class="switch">
      <input type="checkbox" v-model="switchValue" @change="$emit('input', switchValue)">
      <span class="slider round"></span>
    </div>
    <span class="label">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: "customSwitch",
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: () => ''
    },
    value: {
      type: Boolean,
      default: () => false,
      required: true
    }
  },
  data () {
    return {
      switchValue: false
    }
  },
  created() {
    this.switchValue = this.value
  },
  watch: {
    value: {
      handler (val) {
        if (val !== this.switchValue) {
          this.switchValue = val
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.custom-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        right: 3px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }

    input:checked + .slider {
      background-color: #2196F3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(-15px);
      -ms-transform: translateX(-15px);
      transform: translateX(-15px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 25px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .label {
    cursor: pointer;
  }
}
</style>
